<template>
  <div class="rf-input-beds">
    <RFInput :label="$t('Beds')" @click="openBeds = !openBeds" readonly/>
    <div class="beds" v-if="openBeds">
      <div class="bed-type-number" v-for="(bed, index) in beds" :key="index">
        <FontAwesomeIcon class="cursor-pointer" :icon="['fas', 'minus']" @click="sub(bed)"/>
        {{`${bed.number} ${$t(bed.name)}` | replaceUnderscore | toUpperCase}}
        <FontAwesomeIcon class="cursor-pointer" :icon="['fas', 'plus']" @click="add(bed)"/>
      </div>
      <div class="error" v-if="error">{{$t('You have achieve limit of beds.')}}</div>
    </div>
    <div class="added-beds" v-if="bedMapsLength">
      <div class="bed-types" v-for="(number, name) in bedMaps" :key="name">
        <div class="bed-type" :style="{ backgroundColor: color }" v-if="number" >
          {{`${number} ${$t(name)}` | replaceUnderscore | toUpperCase}}
          <FontAwesomeIcon class="cursor-pointer ml-2" :icon="['fas', 'times']" @click="removeBed(name)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import _reduce from 'lodash/reduce'
import { bedTypes } from '@/enums'

export default {
  name: 'RFInputBeds',
  components: {
    RFInput,
  },
  props: {
    bedMap: Object,
    max: {
      type: Number,
      default: null,
    },
    color: {
      type: String,
      default: '#24425B',
    }
  },
  data() {
    return {
      bedTypes,
      beds: [],
      openBeds: false,
      bedMaps: {},
      bedMapsLength: null,
      error: false,
    }
  },
  beforeMount() {
    this.bedMaps = this.bedMap || {}
    Object.keys(this.bedTypes).forEach(key => this.beds.push({ name: key, number: 0 }))

    Object.entries(this.bedMaps).forEach(entry => {
      const index = this.beds.findIndex(bed => bed.name === entry[0])
      this.beds[index].number = entry[1]
    })
  },
  computed: {
    totalPerson() {
      return _reduce(this.beds, (accumulator, bed) => {
        return bed.name === this.bedTypes.DOUBLE || bed.name === this.bedTypes.KING_BED
              ? (bed.number * 2) + accumulator
              : bed.number + accumulator
      }, 0)
    },
  },
  methods: {
    sub(bed) {
      if (bed.number > 0) {
        bed.number--
      }

      if (!bed.number) {
        delete this.bedMaps[bed.name.toUpperCase()]
      }
      this.emitBeds()
    },
    add(bed) {
      if (this.max && this.totalPerson === this.max) {
        return false
      }

      bed.number++
      this.bedMaps[bed.name.toUpperCase()] = bed.number
      this.emitBeds()
    },
    removeBed(name) {
      const index = this.beds.findIndex(bed => bed.name === name.toUpperCase())
      this.beds[index].number = 0
      delete this.bedMaps[name.toUpperCase()]
      this.emitBeds()
    },
    emitBeds() {
      this.$emit('input', this.bedMaps)
    },
    focusIn() {
      this.openBeds = !this.openBeds
    },
  },
  watch: {
    beds: {
      deep: true,
      handler() {
        this.bedMapsLength = Object.keys(this.bedMaps).length
      }
    },
    totalPerson: {
      handler() {
        this.error = this.max && this.totalPerson === this.max
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-input-beds {
  position: relative;

  .beds {
    position: absolute;
    z-index: 10;
    width: 100%;
    background: $white;
    border: 1px solid $dark-blue;
    border-top: none;

    .bed-type-number {
      @include font-style($montserrat, 'semibold', $font-14);
      display: flex;
      justify-content: space-between;
      color: $dark-blue;
      text-align: center;
      padding: 10px;
    }
    .error {
      @include font-style($montserrat, 'semibold', $font-14);
      color: $danger;
      text-align: center;
    }
  }
  .added-beds {
    display: flex;
    align-items: center;

    .bed-types {
      .bed-type {
        @include font-style($montserrat-light, 'light', $font-10);
        border: 1px solid $dark-blue;
        color: $white;
        border-radius: 10px;
        padding: 0 10px;
        width: fit-content;
        margin: 5px 5px 0 0;
      }
    }
  }
}
</style>
