<template>
  <div class="edit-apartment">
    <h1 class="title">{{$t('Edit Apartment')}}</h1>
    <ValidationObserver ref="validator">
      <form class="edit-apartment-form" @submit.prevent>
        <b-row>
          <b-col cols="4">
            <RFValidation rules="required" name="Name">
              <RFInput label="Name" v-model="apartment.name"/>
            </RFValidation>
          </b-col>
          <b-col cols="4">
            <RFValidation rules="required" name="Description">
              <RFInput label="Description" v-model="apartment.description"/>
            </RFValidation>
          </b-col>
          <b-col cols="4">
            <RFValidation rules="required" name="Type">
              <RFSelect top-label="Type" @input="apartment.typeId = $event" :value="apartment.type.name" :list="accommodationTypeList"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFValidation rules="required" name="Square Meters">
              <RFInput label="Square Meters" v-model="apartment.sqMeters" is-numeric/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Number Of Person">
              <RFInput label="Number Of Person" type="number" :min="1" v-model="apartment.numOfPerson"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Num Of Bathrooms">
              <RFInput label="Num Of Bathrooms" type="number" :min="1" v-model="apartment.numOfBathrooms"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInput label="Min Stay Month" type="number" :min="1" v-model="apartment.minStayMonth"/>
          </b-col>
          <b-col>
            <RFMultipleInputAutocomplete label="Amenities" v-model="editedAmenities" :list="amenities" :occuped-list="apartment.amenities" property="name"/>
          </b-col>
          <b-col>
            <RFMultipleInputAutocomplete label="House Rules" v-model="editedHouseRules" :list="houseRules" :occuped-list="apartment.rules" property="name"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInputBeds v-model="apartment.beds" :bed-map="apartment.beds"/>
          </b-col>
          <b-col>
            <RFMultipleInputAutocomplete label="Building Characteristics" v-model="editedCharacteristics" :occuped-list="apartment.characteristics" :list="characteristics" property="name"/>
          </b-col>
          <b-col>
            <RFInput label="Business Unit" v-model="apartment.businessUnit"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInput label="Certificazione Energetica" v-model="apartment.certEnergetica" />
          </b-col>
          <b-col>
            <RFInput label="Item Code" v-model="apartment.itemCode" />
          </b-col>
          <b-col>
            <RFInput label="Piano dei Conti" v-model="apartment.pianoDeiConti" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInput label="Admin Comment" v-model="apartment.adminComment" :value="apartment.adminComment"/>
          </b-col>
          <b-col>
            <RFMultipleInputAutocomplete label="Owners" v-model="editedOwners" :list="owners" :occuped-list="apartment.owners" property="name"/>
          </b-col>
          <b-col>
            <RFCheckbox class="mt-4" label="Has gas" v-model="apartment.hasGas" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="auto">
            <RFCheckbox class="mt-4" label="Use contract template with private owner" v-model="apartment.ownerContractTemplate" />
          </b-col>
        </b-row>

        <h4 class="admin-subtitle mt-5">{{$t('Address')}}</h4>

        <b-row>
          <b-col>
            <RFValidation rules="required" name="Country">
              <RFInput label="Country" v-model="apartment.addressCountry"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="City">
              <RFInput label="City" v-model="apartment.addressCity"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Zip Code">
              <RFInput label="Zip Code" v-model="apartment.addressZipCode" is-numeric/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFValidation rules="required" name="Street">
              <RFInput label="Street" v-model="apartment.addressStreet"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Number">
              <RFInput label="Number" v-model="apartment.addressNumber" is-numeric/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFInput label="Floor" v-model="apartment.floor"/>
          </b-col>
        </b-row>

        <h4 class="admin-subtitle mt-5">{{$t('Amounts')}}</h4>

        <b-row>
          <b-col>
            <RFValidation rules="required" name="Monthly Amount">
              <RFCurrencyInput label="Monthly Amount" v-model="apartment.monthlyAmount"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Deposit Amount">
              <RFCurrencyInput label="Deposit Amount" v-model="apartment.depositAmount"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Service Fees">
              <RFCurrencyInput label="Service Fees" v-model="apartment.serviceFees"/>
            </RFValidation>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <RFValidation rules="required" name="Oneri Amount">
              <RFCurrencyInput label="Oneri Amount" v-model="apartment.oneriAmount"/>
            </RFValidation>
          </b-col>
          <b-col cols="4">
            <RFValidation rules="required" name="Service Amount">
              <RFCurrencyInput label="Service Amount" v-model="apartment.serviceAmount"/>
            </RFValidation>
          </b-col>
          <b-col cols="auto">
            <RFCheckbox class="mt-4" label="Pay only with bank transfer" v-model="apartment.bankTransferMonthlyPayments" />
          </b-col>
        </b-row>

        <h4 class="admin-subtitle mt-5">{{$t('Cadastral Data')}}</h4>

        <b-row>
          <b-col>
            <RFInput label="Category" v-model="apartment.catastoCategory"/>
          </b-col>
          <b-col>
            <RFInput label="Foglio" v-model="apartment.catastoFoglio"/>
          </b-col>
          <b-col>
            <RFInput label="Particella" v-model="apartment.catastoParticella"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInput label="Subalterno" v-model="apartment.catastoSubalterno"/>
          </b-col>
          <b-col>
            <RFCurrencyInput label="Rendita" v-model="apartment.catastoRendita"/>
          </b-col>
          <b-col>
            <RFInput label="Vani" v-model="apartment.catastoVani"/>
          </b-col>
        </b-row>

        <b-row class="apartment-section">
          <b-col class="mb-4">
            <div class="header d-flex justify-content-between">
              <div class="section-title">{{$t('Apartment Images')}}</div>
              <div class="add-images-label cursor-pointer" @click="openImagesModal">
                <FontAwesomeIcon :icon="['fas', 'plus-circle']" color="#5CB86C" /> {{$t('Add Images')}}
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <AdminImageList :images="this.images" :selected-image-index="selectedImageIndex" :selected-planimetry-index="selectedPlanimetryIndex" @planimetry="selectedPlanimetryIndex = $event"
              @select="selectedImageIndex = $event" @delete="deleteImage" @images="reorderImages" :editable="false"
              min-height="300px" @edit="editImage"/>
          </b-col>
          <b-col class="mt-4">
            <p class="admin-aclaration">{{$t(`Images and videos max size is ${fileSizeLimit} MB (Max size recommended: ${fileSizeLimitRecommended} MB)`)}}</p>
          </b-col>
        </b-row>
        <b-row class="justify-content-end mb-5">
          <b-col cols="auto">
            <RFButton class="mt-3" size="small" label="Save" background="#5CB86C" v-if="!loading" @on-click="save"/>
            <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
    <AddImagesModal @update="updateImages" :accept-videos="true" :validate-file-size-limit="true"/>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFCurrencyInput from '@/components/forms/RFCurrencyInput'
import RFButton from '@/components/forms/RFButton'
import RFSelect from '@/components/forms/RFSelect'
import RFFile from '@/components/forms/RFFile'
import RFTextArea from '@/components/forms/RFTextArea'
import RFMultipleInputAutocomplete from '@/components/forms/RFMultipleInputAutocomplete'
import RFValidation from '@/components/forms/RFValidation'
import RFLoader from '@/components/forms/RFLoader'
import RFInputBeds from '@/components/forms/RFInputBeds'
import RFCheckbox from '@/components/forms/RFCheckbox'

import AdminImageList from '@/components/admin/AdminImageList'
import AdminInfoList from '@/components/admin/AdminInfoList'

import AddImagesModal from '@/components/admin/AddImagesModal'
import AddInfoModal from '@/components/admin/AddInfoModal'

import {mapState} from 'vuex'
import {store} from '@/stores'
import {adminService, apartmentService} from '@/services/admin'
import {extractIds} from '@/helpers/functions'

export default {
  name: 'AdminEditApartment',
  components: {
    RFInput,
    RFCurrencyInput,
    RFButton,
    RFSelect,
    RFFile,
    RFTextArea,
    RFMultipleInputAutocomplete,
    RFValidation,
    RFLoader,
    RFInputBeds,
    RFCheckbox,


    AdminImageList,
    AdminInfoList,

    AddImagesModal,
    AddInfoModal,
  },
  beforeRouteEnter(to, from, next) {
   if(!store.state.adminApartmentStore.apartment) {
      next({ name: 'apartment-list' })
    } else {
      next()
    }
  },
  data() {
    return {
      apartment: null,
      images:[],
      selectedImageIndex: null,
      selectedPlanimetryIndex: null,
      loading: false,
      accommodationTypeList: [],
      editedAmenities: [],
      editedHouseRules: [],
      editedCharacteristics: [],
      editedOwners: [],
      fileSizeLimit: 100, //In MB
      fileSizeLimitRecommended: 10, //In MB
    }
  },
  computed: {
    ...mapState({
      storedApartment: state => state.adminApartmentStore.apartment,
      amenities: state => state.adminAmenityStore.amenities,
      houseRules: state => state.adminHouseRuleStore.houseRules,
      characteristics: state => state.adminCharacteristicStore.characteristics,
      owners: state => state.adminOwnerStore.owners,
      accommodationTypes: state => state.accommodationTypeStore.accommodationTypes,
    })
  },
  async beforeMount() {
    this.apartment = { ...this.storedApartment }
    this.images =  this.apartment.imagesAll
    this.selectedImageIndex = this.apartment.imagesAll
                              ? this.apartment.imagesAll.findIndex(image => image.isPrimary)
                              : 0
    this.selectedPlanimetryIndex = this.apartment.imagesAll
                           ? this.apartment.imagesAll.findIndex(image => image.isPlan)
                           : 0
    this.accommodationTypeList = this.accommodationTypes.map(item => ({ value: item.id, label: item.name }))
  },
  watch: {
    editedAmenities: {
      deep: true,
      handler() {
        this.apartment.amenitiesIds = extractIds(this.editedAmenities)
      }
    },
    editedHouseRules: {
      deep: true,
      handler() {
        this.apartment.houseRuleIds = extractIds(this.editedHouseRules)
      }
    },
    editedCharacteristics: {
      deep: true,
      handler() {
        this.apartment.characteristicIds = extractIds(this.editedCharacteristics)
      }
    },
    editedOwners: {
      deep: true,
      handler() {
        this.apartment.ownerIds = extractIds(this.editedOwners)
      }
    }
  },
  methods: {
    openImagesModal() {
      this.$root.$emit('bv::show::modal', 'add-images-modal')
    },
    async deleteImage(id) {
      await adminService.deleteImage(id);
      await this.getApartmentImages();

    },
    async editImage(id) {
      await adminService.editImage(id);
      await this.getApartmentImages();

    },
    async updateImages(addedImages, selectedImageIndex, selectedPlanimetryIndex) {
      let count = this.images.length
      addedImages.forEach((file, index) => {
        file.orderIndex = count + index;
      });
      const newImages = await Promise.all(
        addedImages.map(async (image, index) => {
          const form = new FormData()

          form.append('file', image)
          form.append('isPrimary', selectedImageIndex === index)
          form.append('orderIndex', image.orderIndex)
          form.append('isPlan', selectedPlanimetryIndex === index)


          const { data: newImage } = await apartmentService.addResource(this.apartment.id, form)

          return newImage
        })
      )
      await this.getApartmentImages();
    },
    async save() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.apartment.numOfBathrooms = parseInt(this.apartment.numOfBathrooms)
        this.apartment.numOfPerson = parseInt(this.apartment.numOfPerson)
        this.apartment.sqMeters = parseInt(this.apartment.sqMeters)
        this.apartment.minStayMonth = parseInt(this.apartment.minStayMonth)

        // Adjust orderIndex based on selectedImageIndex
        if (this.selectedImageIndex !== 0) {
          this.images.forEach((file, index) => {
            if (index === this.selectedImageIndex) {
              file.orderIndex = 0;
            } else if (index < this.selectedImageIndex) {
              file.orderIndex = index + 1;
            } else {
              file.orderIndex = index;
            }
          });
        } else {
          this.images.forEach((file, index) => {
            file.orderIndex = index;
          });
        }

        const response = await apartmentService.editApartment(this.apartment)

        if (response.status === 200) {
          Object.assign(this.apartment, response.data)
          this.apartment.images = this.images
          this.apartment.imagesAll = this.images
          this.$toasted.success(this.$i18n.t(`Apartment successfully edited!`))
        } else {
          this.$toasted.error(this.$i18n.t(`Error during edit apartment`))
        }
      }
    },
    async getApartmentImages() {
      this.images = await apartmentService.getApartmentImages(this.apartment.id)
      this.images.sort((a, b) => a.orderIndex - b.orderIndex)

      this.selectedImageIndex = this.images.findIndex(image => image.isPrimary)
      this.selectedPlanimetryIndex = this.images.findIndex(image => image.isPlan)
      this.apartment.imagesAll = this.images;
      this.$store.commit('adminApartmentStore/setImages', this.apartment.imagesAll)
    },
    reorderImages(images){
      this.images = images
      this.apartment.images = images
      this.apartment.imagesAll = images
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-apartment {
  .title {
    @include font-style($montserrat, 'semibold', $font-30);
    color: $dark-blue;
    text-transform: uppercase;
  }
  .apartment-section {
    margin-top: 40px;

    .header {
      border-bottom: 1px solid $dark-gray;
    }
    .section-title {
      @include font-style($montserrat, 'semibold', $font-22);
      color: $dark-blue;
      text-transform: uppercase;
      margin: 0;
    }
    .add-images-label {
      @include font-style($montserrat, 'semibold', $font-16);
      color: $success;
    }
  }
}
</style>
